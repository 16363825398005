.recipient-list-table {
  height: 400px;

  table {
    @include box-shadow(none);

    thead {
      background-color: $transparent;

      th {
        &:first-child {
          padding-left: 10px;
        }
      }

      tr {
        border-left-color: $white;
        border-right-color: $white;
      }
    }

    tbody {
      background-color: $offwhite;
      border-bottom: 1px solid $pale-grey;

      td {
        border: 1px solid $pale-grey;
      }
    }

    th {
      font-weight: $font-weight-bold !important;
      white-space: nowrap;
    }

    thead tr:nth-child(1) th {
      background: $white;
      position: sticky;
      top: 0;
      @include z-index(10);
    }

    tr {
      &:first-child {
        td {
          padding-top: 10px !important;
        }
      }

      &:last-child {
        td {
          padding-bottom: 10px !important;
        }
      }
    }

    td {
      &:first-child {
        padding-left: 10px !important;
      }
    }
  }

  .recipient-list-checkbox {
    .esu9zsz0 {
      margin-right: 0px;
    }
  }
}

.test-send-table,
.add-recipients-table {
  width: 99.8%;

  label {
    font-size: $font-size-sm;
    color: $slate-grey;
  }

  th,
  td {
    font-size: $font-size-sm;
  }

  th {
    color: $slate-grey;
    padding: 10px;
  }

  td {
    color: $ink;
    padding: 10px;
    background-color: $white;
  }
}

.test-send-table {
  tbody {
    border: 1px solid $pale-grey;
  }
}

.test-send-table {
  .rowItem {
    border-color: $cadet;
    width: 180px;
  }

  .css-1vb6v3u,
  .css-85vqaf {
    margin-right: 0px;
  }
}

.test-send-table {
  max-height: 300px;
  @extend %overflow-auto;
  th {
    white-space: nowrap;
  }
  thead tr:nth-child(1) th {
    background: $white;
    position: sticky;
    top: 0;
    @include z-index(10);
  }
}

.add-recipients-table {
  td {
    border: 1px solid $pale-grey;
    padding: 5px 10px;
  }
}

.add-more-contact {
  color: $slate-grey;

  svg {
    width: $spacer;
    margin-right: 5px;
    color: $slate-grey;
  }
}

.cancelIcon {
  &:hover {
    background-color: $transparent !important;
  }

  svg {
    border: 1px solid rgba(22, 38, 59, 0.28);
    color: $white;
    @include border-radius(50%);
    background-color: $cadet;
    padding: 1px;
    width: 11px;
    height: 11px;
    stroke-width: 7px;
  }
}

p.showHideText {
  font-size: $font-size-xs;
}

.tableContainer {
  max-height: 20vh;
  overflow-y: auto;
}

.hideFirstCol tr th:nth-child(1),
.hideFirstCol tr th:nth-child(2),
.hideFirstCol tr td:nth-child(1),
.hideFirstCol tr td:nth-child(2) {
  display: none;
}
