/* Content Placeholder */
.ph-item {
  padding-top: $spacer * 1.875;
  padding-bottom: $spacer * 0.9375;
  margin-bottom: $spacer * 1.875;
  background-color: $white;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    @include z-index(1);
    width: 500%;
    margin-left: -250%;
    animation: phAnimation 0.8s linear infinite;
    background: linear-gradient(to right, rgba($white, 0) 46%, rgba($white, 0.35) 50%, rgba($white, 0) 54%) 50% 50%;
  }

  > * {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    padding-right: $spacer * 0.9375;
    padding-left: $spacer * 0.9375;
  }
}

.ph-row {
  margin-bottom: $spacer * 0.46875;

  div {
    height: $spacer * 0.625;
    margin-bottom: $spacer * 0.46875;
    background-color: $silver;
  }

  .big,
  &.big div {
    height: $spacer * 1.25;
    margin-bottom: $spacer * 0.9375;
  }
}

.ph-row {
  .empty {
    background-color: rgba($white, 0);
  }
}

.ph-col-2 {
  flex: 0 0 percentage(2 / 12);
}

.ph-col-4 {
  flex: 0 0 percentage(4 / 12);
}

.ph-col-6 {
  flex: 0 0 percentage(6 / 12);
}

.ph-col-8 {
  flex: 0 0 percentage(8 / 12);
}

.ph-col-10 {
  flex: 0 0 percentage(10 / 12);
}

.ph-col-12 {
  flex: 0 0 percentage(12 / 12);
}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: $spacer * 3.75;
  background-color: $silver;
  margin-bottom: $spacer * 0.9375;
  @include border-radius(50%);
  overflow: hidden;

  &::before {
    content: " ";
    display: block;
    padding-top: 100%;
  }
}

.ph-picture {
  height: $spacer * 7.5;
  background-color: $silver;
  margin-bottom: $spacer * 0.9375;
}

@keyframes phAnimation {
  0% {
    @include transform(translate3d(-30%, 0, 0));
  }
  100% {
    @include transform(translate3d(-30%, 0, 0));
  }
}
