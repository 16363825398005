.modalPopup {
  padding: 0 20px;

  h5 {
    color: $slate-grey;
  }

  button {
    .css-bpu19j {
      color: $cadet;
    }
  }
}

.modal-header-title {
  font-size: $font-size-18;
  font-weight: $font-weight-bold;
  color: $slate-grey;

  + button svg {
    color: $cadet;
  }
}

.modalPopup {
  header {
    padding: 20px 20px 28px;
  }

  p {
    font-size: $font-size-sm;
    color: $ink;
  }

  .csv-files-only {
    font-size: $font-size-xs;
    color: $slate-grey;
    top: -22px;
  }
}

.footerButtons {
  padding: 20px !important;
  border-top: 1px solid $pale-grey;
}

.searchbox {
  width: 70%;
  height: 60px;

  input {
    border: 1px solid $cadet;
    padding: 0.5rem 3.4rem 0.5rem 0.875rem;
  }

  .search-message {
    font-size: 0.8125rem;
    color: $cadet;
  }

  .searchIcon,
  .search-clear-icon {
    top: 12px;
    right: 15px;
    stroke-width: 3px;
    color: $pewter;
    @extend %cursor-pointer;
  }

  .search-clear-icon {
    right: 35px;
  }
}

.recipientlistButtons {
  button {
    font-size: $font-size-xs;
    color: $pewter;
    padding: 5px 10px;
    
    svg {
      pointer-events: none;
    }
    
    .uploadFileIcon {
      margin-right: 4px;
      width: 11px;
      stroke-width: 3;
      margin-top: -2px;
    }

    .exportFileIcon {
      @include border-radius(100%);
      stroke: $slate-grey;
      stroke-width: 5;
      border: 2px solid $slate-grey;
      padding: 1px;
      margin-right: 4px;
      margin-top: -2px;
      width: 12px;
      height: 12px;
    }

    .deleteFileIcon {
      height: 11px;
      width: 11px;
      background-color: $ink;
      @include border-radius(100%);
      stroke: $white;
      padding: 2px;
      stroke-width: 5;
      margin-right: 4px;
      margin-top: -2px;
    }
  }
}

.upload-complete {
  padding: 20px 14px 10px 12px;
  background-color: $offwhite;

  .upload-complete-text {
    margin-bottom: 10px;
    color: $slate-grey;
  }
}

.modalPopup {
  .show-details {
    font-size: $font-size-xs;
    color: $slate-grey;
  }
}

.recipientlistButtons {
  button {
    &:hover {
      color: $white;
      background-color: $slate-grey;
      
      &:focus {
        color: $white;
      }

      svg {
        color: $white;
        stroke: $white;
        border-color: $white;
      }

      .deleteFileIcon {
        background-color: $white;
        stroke: $ink;
      }
    }

    &:focus {
      color: $pewter;
    }
  }

  .closeBtn {
    font-size: $font-size-sm;
    color: $slate-grey;

    &:hover,
    &:active,
    &:focus {
      background-color: $transparent;
      color: $slate-grey;
    }
  }
}

// Recipient List File delete Popup
.recipientFiledelete {
  margin: 0.8rem 0 !important;
}

.launchmailText {
  span {
    color: $slate-grey;
  }
  .launch-check-lists {
    color: $cadet;
  }
}

/* Insert Image Popup */
.insertImageUploader {
  margin-bottom: 28px;
  .css-1vw4vcf {
    padding-bottom: 0px;
    padding-top: 0;
    height: 149px;
  }
}

.image-manager {
  font-size: $font-size-sm;
  font-weight: $font-weight-black;
  color: $slate-grey;
}

.thumbnail-container {
  max-height: 350px;
  @extend %overflow-auto;
  
  .thumbnail {
    width: 120px;
    height: 120px;
    overflow: hidden;
    @include border-radius(3px);
    border: 1px solid $silver;
    @extend %cursor-pointer;
    margin: 0 20px 20px 0;
    padding: 2px;

    &.selected {
      border: 2px solid $lighter-brand-green;
    }

    .css-79elbk {
      position: absolute;
      right: -0.5rem;
      top: 0.5rem;
    }

    .e1r10n8r0 {
      position: absolute;
      right: 0.25rem;
      bottom: 0.5rem;
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      @extend %m-auto;
      display: block;
      @include transition(all 0.4s ease-in);
    }

    .nameContainer {
      @include opacity(0);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(22, 38, 59, 0.64);
      @include transition(all 0.3s ease-in);
      padding: 8px;
      visibility: hidden;

      label {
        font-size: $font-size-xs;
        color: $white;
        font-weight: $font-weight-bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        width: 80%;
        overflow: hidden;
      }
    }

    &:hover {
      .nameContainer {
        visibility: visible;
        @include opacity(1);
      }

      img {
        width: 90%;
      }

      .e1r10n8r0 {
        display: block;
      }
    }
  }
}

.imgLoader {
  @include z-index(3);
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
}

.imageLoading {
  @include opacity(0);
}

.image {
  @include opacity(1);
  @include transition(0.3s ease-in-out); 
}

#customModalSize {
  min-height: 516px;
}
