.progress-bar {
  height: $spacer;
  border: 1px solid $pale-grey;
  background-color: $white;
  @include border-radius(16px);
}

.progress-thumb {
  background-color: $black-top;
  left: 0;
  top: 0;
  @include transition(all 0.2s ease-in);
}

.progressText {
  font-size: $font-size-sm;
}
