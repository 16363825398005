.preview-container {
  @extend %m-auto;
  background-color: $pale-grey;
  padding: 42px;
  @include transition(all 0.3s ease-in-out);
  height: calc(100vh - 220px);
  height: -webkit-calc(100vh - 220px);
  height: -moz-calc(100vh - 220px);
  overflow-y: auto;

  &.mobile {
    max-width: 420px;
    padding: 10px;
  }

  .desktop-view {
    @include z-index(3);
  }

  .mobile-view {
    max-width: 400px;
    @include z-index(3);
  }
}
