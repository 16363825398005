%border-none {
  border: none !important;
}

%outline-none {
  outline: none;
}

%pseudo {
  content: "";
  display: block;
  position: absolute;
}

%cursor-pointer {
  cursor: pointer;
}

%cursor-not-allowed {
  cursor: not-allowed !important;
}

%pointer-events-none {
  pointer-events: none;
}

%white-space-normal {
  white-space: normal;
}

%text-decoration-none {
  text-decoration: none;
}

%text-transform-capitalize {
  text-transform: capitalize;
}

%list-style-type-none {
  list-style-type: none;
}

%max-width {
  max-width: none;
}

%m-auto {
  margin: 0 auto;
}

%overflow-auto {
  overflow: auto;
}
