.unsubscribe-screens {
  @include z-index(3);
  position: relative;
  max-width: 600px;
  @extend %m-auto;
  background-color: $white;
  min-height: 330px;
  padding: 2rem;
  @include border-radius(4px);
  @include box-shadow(0 4px 12px 0 rgba(22, 38, 59, 0.3));
  @include transition(all 0.3s ease-in-out);
}

.unauthorize-container,
.unsubscribe-container {
  height: 100vh;
  padding: 0;
  margin-top: -110px;
}

.unsubscribe-container {
  .unsubscribe-screens {
    margin: 6rem auto;
  }
}

.unauthorize-screen {
  padding: 4rem 2rem;
  margin: 6rem auto;

  .alertTriangle {
    color: $yellow;
    height: 45px;
  }

  .link {
    width: 43%;
  }
}

.download-link-screen {
  min-height: 85px;
  margin: 6rem auto;
  p {
    font-size: $font-size-18;
    font-weight: $font-weight-medium;
  }
}
