.recipient_terms + .css-bag037 {
  font-size: $font-size-xs;
  align-items: flex-start;

  .esu9zsz0 {
    margin-top: 2px;
    min-width: 1.25rem;
  }
}

.recipient_terms_label {
  label {
    font-size: $font-size-xs;
    @extend %cursor-pointer;
    color: $slate-grey;
  }
}
