@font-face {
  font-family: 'Roboto', sans-serif;
  src: url('./fonts/Roboto-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Medium', sans-serif;
  src: url('./fonts/Roboto-Medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-roboto-medium;
  font-weight: $font-weight-bold;
}

p {
  font-size: $font-size-sm;
  color: $slate-grey;
  font-weight: $font-weight-medium;
}

body {
  font-family: $font-family-roboto;
  background-color: $offwhite;
  padding-top: 110px;
}

.page-title {
  color: $black-top;
  font-size: $font-size-20;
  font-weight: $font-weight-bold;
}

.error-message {
  font-size: $font-size-sm;
  color: $red;
}

.overflow-x-auto {
  overflow-x: auto;
}

.svg-icon {
  height: 20px;
  width: 20px;
}

svg {
  &.disabled {
    @extend %pointer-events-none;
    stroke: $pewter;
  }
}

.css-85vqaf {
  svg {
    vertical-align: top !important;
  }
}

#popover-root {
  .css-1sl0n0l {
    @include z-index(1051);
  }
}

.disable-overlay {
  position: fixed;
  top: 111px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  @include z-index(99);
}

input {
  background: $transparent;
}

@-webkit-keyframes autofill {
  to {
    color: $pale-grey;
    background: $transparent;
  }
}

.loaderContainer {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete {
  &.disabled {
    @extend %cursor-not-allowed;
    @include opacity(0.5);
  }
}

.emoji-container {
  top: 54px;
  right: 0;
  max-width: fit-content;
  max-height: fit-content;
  box-shadow: rgba(22, 38, 59, 0.28) 0px 4px 12px 0px;
  @include z-index(1);
}

.add-subject__suggestions__item__highlight {
  font-weight: $font-weight-normal !important;
}
