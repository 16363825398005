.header {
  background-color: $ink;
}

.back-composer {
  font-size: $font-size-xs;
  font-weight: $font-weight-black;
  @include line-height(1.42);
  letter-spacing: 0.8px;
  color: $silver;
  padding: 30px 0 12px;

  .back-arrow {
    color: $blue;

    svg {
      stroke-width: 4px;
      width: 12px;
      height: 12px;
      margin-top: -1px;
    }
  }

  .slash {
    padding: 0 7px;
  }

  .backBtn {
    font-size: 0.75rem;
    font-weight: $font-weight-black;
    text-decoration: none;
  }

  .survey-name {
    background-color: $transparent;
    @extend %border-none;
    @include truncate(154px);

    &:enabled {
      cursor: default;
    }
  }
}

.new-campaign {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  color: $pale-grey;
  padding-bottom: 14px;

  h2 {
    font-size: $font-size-base;
    font-family: $font-family-roboto-medium;
    @include truncate(220px);
  }

  .text-email {
    margin-right: 14px;
  }

  .underlined-input {
    @extend %border-none;
    border-bottom: 1px solid $pewter !important;
    background-color: $transparent;
    padding: 4px;
    color: $white;
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
    font-family: $font-family-roboto-medium;
    @include border-radius(0px);
    @include truncate(220px);
  }

  .underlined-input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }
}

.editIcon {
  @extend %cursor-pointer;
  margin-left: 14px;
}

.progressBar {
  height: 6px;
  @include border-radius(3px);
}

.colorBar {
  left: 0;
  top: 0;
  width: 0;
  @include transition(all 0.3s ease-in-out);
  background-image: linear-gradient(91deg, #00c753, #00d4e8);
}

.arrowIcon {
  svg {
    stroke-width: 3;
    @extend %cursor-pointer;
  }

  .disabled {
    color: $slate-grey;
    @include opacity(0.4);
  }
}

.launchCampaign {
  .launchCampaignBtn {
    & + div {
      max-width: 300px;
      left: -70px !important;
      background-color: $black;

      &::after {
        border-top-color: $black;
        left: 72%;
      }
    }
  }
}

.manual-launch-time,
.launch-now-btn {
  font-size: $font-size-sm;
  color: $ink;

  .ek0vkco0 {
    height: 1rem;
    width: 1rem;
  }

  svg {
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
  }
}

.react-datepicker-wrapper {
  margin-left: 30px;

  input {
    border: 1px solid $silver;
    padding: 6px 8px;
    font-size: $font-size-15;
    min-width: 225px;
    color: $slate-grey;
    @include border-radius(3px);
    @extend %outline-none;

    &:disabled {
      @extend %cursor-not-allowed;
      color: $silver;
    }

    &:focus {
      outline: 2px solid $light-brand-green;
    }
  }
}
