.build-preview-tabs {
  padding: 18px 0 0;
  border-bottom: 1px solid $pale-grey;
  margin-bottom: 10px;

  li {
    padding-right: 0px;

    a {
      border-bottom-width: 5px;
      padding: 0.6rem 0;
      outline: none;
      @include line-height(inherit);

      &.css-b3kg2k {
        &:hover {
          border-bottom-color: $brand-green !important;
        }
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.preview-button {
  height: 30px;
  width: 30px;
  padding: 0 !important;
  text-align: center;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    width: 22px;
    height: 22px;
  }

  &.isActive {
    @include border-radius(3px);
    background-color: $ink !important;
  }
}

.tab-disabled {
  @extend %cursor-not-allowed;
  @extend %pointer-events-none;
  @extend %border-none;
}

#test_email_btn.disabled,
#launch_email_btn.disabled {
  color: rgba(247, 249, 250, 0.3);
  background-color: rgba(247, 249, 250, 0.2);
  border-color: $transparent;
  @extend %cursor-not-allowed;
}
