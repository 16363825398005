.quill {
  border: 1px solid $silver;
  border-top: 0px !important;
  margin-bottom: 1rem;
  background-color: $slight-white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  .ql-snow {
    .ql-editor {
      @include line-height(1.5);
      min-height: 300px;
      background-color: $white;
      padding: 22px;
      @include transition(all 0.2s ease-in-out);
      max-height: 385px;
      @extend %overflow-auto;

      .action-button {
        //display: inline-block;
        //width: 208px;
        height: 60px;
        color: $white;
        text-align: center;
        vertical-align: middle;
        @include border-radius(4px);
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
        @extend %text-decoration-none;
        @extend %cursor-pointer;

        span {
          display: inline-table;
        }
      }

      a {
        color: $dark-ocean;
        @extend %text-decoration-none;
      }
    }

    .ql-tooltip {
      .ql-action,
      .ql-remove {
        display: none !important;
      }
    }
  }

  .ql-container {
    font-size: $font-size-sm;
    font-family: $font-family-roboto;

    &.ql-snow {
      @extend %border-none;
      overflow: visible;
    }
  }
}

.ql-toolbar {
  &.ql-snow {
    border-bottom: 1px solid $offwhite !important;
    background-color: $white;
    border-top: 0px !important;
    border-color: $silver;
    display: flex;
    flex-wrap: wrap;

    .ql-stroke {
      stroke: $pewter;
    }

    button {
      &:hover {
        .ql-picker-options {
          .ql-stroke {
            stroke: $pewter !important;
          }

          .ql-picker-item {
            &:hover {
              .ql-stroke {
                stroke: $ocean !important;
              }
            }
          }
        }
      }
    }

    .ql-picker-options .ql-fill {
      fill: $pewter;
    }
  }
}

#customToolbar.ql-snow.ql-toolbar {
  button.ql-active {
    color: $ink;

    .ql-stroke {
      stroke: $ink;
    }
  }

  button {
    color: $pewter;
    font-weight: $font-weight-medium;
    font-family: $font-family-roboto;
    padding: 5px 4px;
    width: auto;
  }

  .utf-characters-body {
    width: 275px;
    max-height: 300px;
    @include box-shadow(rgba(22, 38, 59, 0.28) 0px 4px 12px 0px);
    @include z-index(2);
    @include border-radius(5px);
    background-color: $white;
    border: 1px solid $silver;
    top: 35px;
    left: -110px;
    padding: 10px;

    .ql-utf {
      padding: 6px;
      width: 28px;
      height: 28px;
      color: $ink;
      &:hover {
        background-color: $grey;
        @include border-radius(100%);
      }
    }

    .utf-charatcers-body-fixed {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.ql-separator {
  margin: 0 5px;
  color: $silver;
  font-size: $font-size-20;
}

.ql-picker-label {
  &::before {
    font-family: $font-family-roboto;
    color: $pewter;
    font-size: $font-size-xs;
  }
}

button.ql-undo,
button.ql-redo {
  svg {
    width: 16px;
  }
}

.draftSaving {
  width: 16%;
  top: 5px;
  right: 12px;
  left: auto;

  .draftSavingMsg {
    padding: 0.8rem 1rem;
    @include border-radius(4px);
  }
}

.subjectBox {
  .add-subject__highlighter {
    padding: 0.875rem 1.25rem !important;
    @extend %border-none;
    overflow: initial !important;
  }

  .add-subject__control {
    width: 100% !important;

    .add-subject__input {
      display: block;
      background-clip: padding-box;
      -webkit-transition: border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      background-color: $white !important;
      @include border-radius(4px);
      padding: 0.875rem 1.25rem !important;
      @include line-height(20px);
      border: 1px solid $pale-grey !important;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-color: $silver !important;
      font-size: $font-size-sm !important;
      color: $slate-grey;
      width: 100% !important;
      @include input-placeholder {
        @include opacity(0.7);
      }

      &:focus {
        @extend %outline-none;
        border-color: $brand-green;
      }
    }
  }
}

.add-subject__suggestions {
  width: 18%;

  .add-subject__suggestions__list {
    max-height: 190px;
    overflow-y: auto;
    @include border-radius(3px);
    box-shadow: rgba(9, 30, 66, 0.31) 0px 0px 1px,
      rgba(9, 30, 66, 0.25) 0px 4px 8px -2px;

    .add-subject__suggestions__item {
      padding: 8px 14px !important;
      @extend %cursor-pointer;
      font-size: $font-size-sm;
      height: 36px;
      @include line-height(20px);
      border-bottom: 0px !important;

      &:hover {
        background-color: $pale-grey !important;
      }

      &--focused {
        background-color: $pale-grey !important;
      }
    }

    .add-subject__suggestions__item__display {
      @include truncate(200px);
      display: block;
    }
  }
}

.ql-container {
  .ql-mention-list-container {
    width: 18%;
    max-height: 190px;
    overflow-y: auto;
    box-shadow: rgba(9, 30, 66, 0.31) 0px 0px 1px,
      rgba(9, 30, 66, 0.25) 0px 4px 8px -2px !important;
    @include border-radius(3px);

    .ql-mention-list-item {
      padding: 8px 14px;
      @extend %cursor-pointer;
      font-size: $font-size-sm;
      height: 36px;
      @include line-height(20px);
      @include truncate(220px);
      display: block;

      &.selected {
        background-color: $pale-grey;
      }
    }
  }

  .mention {
    padding: 0px 0 2px;
  }
}

.ql-undo,
.ql-redo,
.ql-insertCallToAction,
.ql-insertLink,
.ql-fieldMerge,
.ql-insertOptOutLink,
.ql-insertUtfCharacters {
  background-color: $offwhite !important;
  @include border-radius(3px);
  height: 30px !important;
  font-size: $font-size-xs !important;
  margin-right: 2px;
}

.ql-insertCallToAction,
.ql-insertLink,
.ql-insertOptOutLink {
  width: auto !important;
  padding: 2px 5px !important;
}

.ql-snow {
  .ql-picker {
    &.ql-font,
    &.ql-size {
      text-align: left;
      width: 95px !important;
    }
  }
}

.ql-redo {
  transform: rotateY(180deg);
}

.survey {
  .survey-url {
    font-size: $font-size-sm;
  }
}

.subjectBox,
.editorArea {
  &:focus-within {
    .field-merge,
    .ql-fieldMerge {
      z-index: 1;
      @include opacity(1);
    }
  }

  .ql-fieldMerge {
    @include opacity(0);
    @include transition(all 0.2s ease-in-out);
  }
}

textarea.code-area {
  width: 100%;
  height: 60vh;
  @include line-height(1.75);
  font-size: $font-size-sm;
  resize: none;
  font-weight: $font-weight-normal;
  font-family: monospace;
}

.desktop-view,
.mobile-view {
  .ql-container {
    @include box-shadow(0 4px 12px 0 rgba(22, 38, 59, 0.3));
  }
}

.ql-font {
  span[data-label='Arial'] {
    &::before {
      font-family: 'Arial';
    }
  }
  span[data-label='Courier'] {
    &::before {
      font-family: 'Courier';
    }
  }
  span[data-label='Georgia'] {
    &::before {
      font-family: 'Georgia';
    }
  }
  span[data-label='Verdana'] {
    &::before {
      font-family: 'Verdana';
    }
  }
}

.ql-font-arial {
  font-family: 'Arial';
}

.ql-font-courier {
  font-family: 'Courier';
}

.ql-font-georgia {
  font-family: 'Georgia';
}

.ql-font-monospace {
  font-family: 'Monospace';
}

.ql-font-sans-serif {
  font-family: 'Sans Serif';
}

.ql-font-serif {
  font-family: 'Serif';
}

.ql-font-verdana {
  font-family: 'Verdana';
}

.editorArea {
  span[contenteditable='true'] {
    -webkit-user-modify: read-only;
    @extend %pointer-events-none;
    -moz-user-select: none;
  }

  .link-insertion,
  .optout-insertion {
    .survey-preview-link {
      display: none;
    }
  }
}

.preview-container {
  span[contenteditable='true'] {
    @extend %pointer-events-none;
    -webkit-user-modify: read-only;
    -moz--user-modify: read-only;
  }

  .link-insertion,
  .optout-insertion {
    .survey-editor-link {
      display: none;
    }

    .survey-preview-link {
      @extend %cursor-pointer;
    }

    span[contenteditable='true'] {
      pointer-events: auto;
    }
  }
}

.ql-editor {
  span[contenteditable='false'] {
    cursor: text;
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -o-user-select: text;
  }

  fieldtag {
    span {
      height: 24px;
      width: 65px;
      @include border-radius(6px);
      background-color: $field-tag;
      padding: 0px 3px 2px;
    }
  }
}

.tooltip-text,
.align-tooltip {
  max-width: 200px;
  padding: 0.3rem 0.6rem;
  color: $white;
  text-align: left;
  font-size: $font-size-xs;
  background-color: rgba(46, 48, 51, 0.85);
  @include border-radius(0.25rem);
  position: absolute;
  @include z-index(3);
  visibility: hidden;
  @include opacity(0.85);
  @extend %pointer-events-none;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-color: $transparent;
    border-top-color: rgba(70, 138, 240, 0.9);
    border-width: 6px;
    border-style: solid;
  }
}

.ql-toolbar {
  .tooltip-trigger {
    &:hover ~ .tooltip-text {
      visibility: visible;
      @include opacity(1);
    }
  }
}

.ql-toolbar {
  .ql-picker-item {
    &:hover ~ .align-tooltip {
      visibility: visible;
      @include opacity(0.85);
    }
  }
}

.align-tooltip {
  left: 110%;
  text-transform: capitalize;

  &::after {
    top: 50%;
    left: -12px;
    transform: translateY(-50%);
    border-color: $transparent;
    border-right-color: rgba(46, 48, 51, 0.85) !important;
    border-width: 6px;
    border-style: solid;
  }
}

.ql-toolbar {
  &.ql-snow {
    button {
      svg {
        top: 40% !important;
        float: none !important;
      }
    }

    .ql-fill {
      fill: $pewter;
    }

    .ql-stroke {
      &.ql-fill {
        fill: $pewter;
      }
    }
  }

  .ql-snow {
    button {
      svg {
        top: 40% !important;
        float: none !important;
      }
    }
  }
}

.ql-snow {
  .ql-icon-picker {
    .ql-picker-item {
      width: 26px !important;
      padding: 3px 4px !important;
    }
  }
}

.emojiImage {
  width: 15px;
}

.choose-color-txt {
  label {
    font-size: $font-size-sm;
    color: $black-top;
  }

  .colorpicker-btn {
    padding: 0.3rem 0.5rem;
    background-color: $white;
    border: 1px solid $silver;
    color: $silver;
    width: 50px;
    @include border-radius(0.125rem);

    .colorpicker-text,
    .colorpicker-button {
      height: 20px;
      @include border-radius(0.125rem);
      @include box-shadow(inset 0 0 1px rgba(0, 0, 0, 0.4));
      background-color: $dark-ocean;
    }

    .colorpicker-button {
      background-color: $orange;
    }
  }

  .sketch-picker-absolute {
    position: absolute;
    @include z-index(2);
    left: 33px;
    top: 33px;
  }

  .sketch-picker-close {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}

.choose-size-txt {
  label {
    font-size: $font-size-sm;
    color: $black-top;
  }

  .size-btn {
    width: 50px;
    background-color: $white;
    border: 1px solid $silver;
    @include border-radius(0.125rem);
    font-size: $font-size-sm;
    color: $black-top;
    height: 1.8rem;
    padding: 5px;
    outline: none;
  }
}

#customToolbar {
  .ql-fieldMerge {
    opacity: 0;
    z-index: -1;
  }

  .ql-fieldMerge {
    &.isFocused {
      opacity: 1;
      z-index: 1;
    }
  } 
}
