.error-message {
  &.tooltip {
    @include z-index(1);
    background-color: $light-red;
    right: 0;
    top: 42px;
    @include transition(all 0.2s ease-out 0s);
    @include opacity(1);
    color: $slate-grey;
    padding: 8px;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    font-family: $font-family-roboto-medium;
    left: 0;
    @include border-radius(4px);
    @include line-height(1.5);

    &::after {
      content: "";
      position: absolute;
      left: calc(50% - 5px);
      top: -10px;
      height: 0;
      width: 0;
      border-left: 10px solid $transparent;
      border-right: 10px solid $transparent;
      border-bottom: 10px solid $light-red;
    }

    svg {
      margin-right: 12px;
      min-width: 24px;
    }
  }
}

.form-details {
  .css-pqm3yq {
    border: 1px solid $red;
  }
}

.error-message {
  span {
    font-weight: $font-weight-bold;
  }
}
