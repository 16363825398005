.alert-Container {
  .alertBox {
    flex: 1;
  }

  .closeBtn {
    right: 1rem;
  }
}

.alertContainer {
  right: 1rem;
  top: 115px;
  @include z-index(9999);
  font-size: $font-size-15;
  @include box-shadow(rgba(22, 38, 59, 0.2) 0px 3px 8px 0px);

  label {
    flex: 1;
    font-weight: $font-weight-bold;
  }
  span {
    @extend %cursor-pointer;
  }
}

.exportMessage {
  @include box-shadow(rgba(22, 38, 59, 0.2) 0px 3px 10px 0px);
  background-color: $white;
  @extend %border-none;
  color: $slate-grey;
  top: 50%;
  right: auto;
}

.cancelButton {
  width: 1.2rem !important;
}
