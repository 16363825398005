// Pagination
.pagination {
  @extend %list-style-type-none;
  @include border-radius(0.25rem);
}

.page-item {
  &.active {
    .page-link {
      @include z-index(1);
      color: $white;
      background-color: $dark-ocean;
      border-color: $dark-ocean;
    }
  }
}

.page-link {
  @extend %cursor-pointer;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  @include line-height(1.25);
  color: $dark-ocean;
  background-color: $white;
  border: 1px solid $pale-grey;
  @extend %text-decoration-none;

  &:hover {
    color: $ocean;
    @extend %text-decoration-none;
    background-color: $pale-grey;
    border-color: $pale-grey;
  }
}

.pageInput {
  width: 62px !important;
  margin-right: 10px;
  height: 30px;
}

.pageDetails {
  font-size: $font-size-sm;

  input[type="number"] {
    -moz-appearance: textfield;
    border: 1px solid $cadet;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }

    &:disabled {
      border: 1px solid $cadet;
    }
  }
}
