@mixin vendor($property, $value) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
  -o-#{$property}: $value;
  #{$property}: $value;
}

@mixin box-shadow($shadow) {
  @include vendor(box-shadow, $shadow);
}

@mixin border-radius($radius) {
  @include vendor(border-radius, $radius);
}

@mixin transition($transition) {
  @include vendor(transition, $transition);
}

@mixin transform($property) {
  @include vendor(transform, $property);
}

@mixin input-placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-input-placeholder {
    @content;
  }
  &:-moz-input-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin z-index($property) {
  z-index: $property;
}

@mixin opacity($property) {
  opacity: $property;
}

@mixin line-height($lh) {
  line-height: $lh;
}

@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
