.instructions {
    right: 0;
    top: -4px;
  .infoIcon {
    @include border-radius(100%);
    color: $blue;
    background-color: $transparent;
    height: 16px;
    width: 16px;
    font-size: $font-size-xxs;
    padding: 0;
    border: 1px solid $blue;
    font-weight: $font-weight-medium;
    @extend %cursor-pointer;
    margin-top: -4px;
    margin-left: 6px;

    & + div {
      background-color: $slate-grey;
      padding: 10px 15px;
      left: -1px !important;

      &.css-cwm8ko {
        padding: 0.8rem;
      }

      &[open] {
        @include opacity(1);
      }

      p {
        color: $pale-grey;
        width: 212px;
        @extend %white-space-normal;
        font-size: $font-size-xs;
        margin: 0;
        font-weight: $font-weight-medium;
      }

      &::after {
        border-top-color: $slate-grey;
      }
    }

    span {
      @include line-height(15px);
    }
  }
}

.modalPopup {
  .infoIcon {
    &:hover,
    &:focus {
      background-color: $transparent;
      color: $blue;
    }
  }
}

.uploadContainer {
  position: relative;

  .css-1vw4vcf {
    padding-bottom: 40px;

    .css-15nib13,
    .css-9n16fy,
    .css-vkear1 {
      font-weight: 600;
      font-family: $font-family-roboto-medium;
      color: $slate-grey;
    }

    .css-9n16fy {
      a {
        text-decoration: underline;
      }
    }
  }

  .fileInstruction {
    position: absolute;
    text-align: center;
    font-size: 12px;
    bottom: 14px;
    color: $slate-grey;
  }

  .fileSizeInstruction {
    font-style:normal;
    bottom: 6px;
  }
}
