// Actions Button Dropdown
.actions {
  .actionsBtn {
    width: 113px;
    font-weight: $font-weight-medium;
    @include border-radius(3px);
    padding: 10px 18px;

    svg {
      width: 12px;
      height: 12px;
      color: $slate-grey;
      margin-left: 6px;
    }

    &:hover,
    &:focus {
      background-color: $ink;
      color: $pale-grey;
      svg {
        color: $pale-grey;
      }
    }
  }
}

.actions {
  ul {
    width: 240px;
    right: 0;
    left: -125px;
    margin-top: 2px;
    overflow: visible;

    a {
      font-size: $font-size-sm;
      color: $ink;
      justify-content: start;
      padding: 10px 14px;

      &:hover {
        background-color: $duck-egg-blue-70;
      }

      svg {
        border: 1px solid $slate-grey;
        color: $slate-grey;
        @include border-radius(50%);
        margin-right: 5px;
        width: 12px;
        height: 12px;
      }
    }

    .efhrsw50 {
      top: 39px !important;
      @extend %white-space-normal;
    }
  }
}

.field-merge-btn {
  & + ul {
    left: auto;
    right: 0;
  }
}
