$font-family-roboto: "Roboto", sans-serif;
$font-family-roboto-medium: "Roboto Medium", sans-serif;
$font-family-arial: Arial, Helvetica, sans-serif;
$font-family-times: "Times New Roman", Times, serif;

// Colors
$brand-green: #00cc52;
$light-brand-green: #5ae070;
$lighter-brand-green: #82eb7f;
$light-blue: #00d3e7;
$teal: #00bebe;
$purple: #7462e0;
$lilac: #a279ff;
$pewter: #627585;
$cadet: #8394a3;
$offwhite: #f7f9fa;
$silver: #cbd2d6;
$black: #000000;
$black-top: #2e3033;
$light-green: #cef5d4;
$red: #f5496c;
$light-red: #ffdbe2;
$orange: #e07a04;
$light-orange: #ffead1;
$blue: #428fdb;
$light-blue: #d4e7fa;
$purple: #725ee0;
$dark-purple: #5641cc;
$ocean: #1b73b3;
$dark-ocean: #07558c;
$forest: #1a825f;
$sangria: #b3365f;
$plum: #805980;
$teal: #008594;
$sea-blue: #097ea1;
$olive: #7a7540;
$coffee: #99664d;
$yellow: #f6dd11;
$ink: #16263b;
$white: #ffffff;
$slight-white: #f6f6f6;
$pale-grey: #e4e8eb;
$light-grey: #eeeeee;
$mid-grey: #e1dedf;
$grey: #f4f4f4;
$slate-grey: #3e5467;
$text-disable: #6c757d;
$bg-disable: #e4e1e1;
$grey-mid: #cccccc;
$fieldtag: #495057;
$transparent: transparent;
$info-bg: rgba(62, 84, 103, 0.16);
$duck-egg-blue-70: rgba(212, 231, 250, 0.7);
$field-tag: #D3E1EB;

// Font Weights
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-black: 900;
$font-weight-normal: normal;

// Font Sizes
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-xxs: $font-size-base * 0.625; // 10px
$font-size-xs: $font-size-base * 0.75; // 12px
$font-size-sm: $font-size-base * 0.875; // 14px
$font-size-15: $font-size-base * 0.9375; // 15px
$font-size-18: $font-size-base * 1.125; // 18px
$font-size-20: $font-size-base * 1.25; // 20px
$font-size-22: $font-size-base * 1.375; // 22px
$font-size-24: $font-size-base * 1.5; // 24px
$font-size-30: $font-size-base * 1.875; // 30px
$font-size-lg: $font-size-base * 2.5; // 40px

// Spacer
$spacer: 1rem; // Used for custom width, height, padding and margin calculation
