.loaderOverlay {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $white;
  @include transition(opacity 0.15s linear);
  @include z-index(1071);
  @include opacity(0.6);
  
  .loaderRounded {
    width: 100vw;
    height: 100vh;
  }
}
