.form-row {
  .form-label {
    font-size: $font-size-sm;
    width: $spacer * 10;
  }
}

.form-item {
  flex: 1;
  position: relative;

  ul {
    min-width: 12rem;
  }
}

.listRow {
  .rowItem {
    margin-right: $spacer * 0.75;
  }
}

.isExportDisabled {
  @extend %cursor-not-allowed;
  @extend %pointer-events-none;
  background-color: $bg-disable;
  color: $text-disable !important;
}

.entrySeedList {
  .badgeSeedlist {
    padding: 0.3rem 1rem;
    @include line-height(1.5);
  }
}

.recipients-section {
  @include border-radius(4px);
  background-color: $pale-grey;
}

.recipients,
.form-details {
  padding: 20px;

  .show-more-btn {
    color: $slate-grey;
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
    font-family: $font-family-roboto-medium;
    padding: 0 0.875rem;

    &:hover,
    &:focus {
      background-color: $transparent;
    }
  }
}

.to-from {
  color: $pewter;
  font-size: $font-size-sm;
  margin-top: 2px;
  font-weight: $font-weight-bold;
}

.recipients-btn {
  color: $slate-grey;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;

  button {
    padding: 0 0.875rem;

    &:hover,
    &:focus {
      background-color: $transparent;
    }
  }

  .plusIcon {
    @include border-radius(50%);
    border: 1px solid $slate-grey;
    margin-right: 6px;
    margin-top: -1px;
  }

  .recipient-badge {
    margin-left: 1rem;
    height: 30px;
    @include border-radius(15px);
    padding: 8px 16px 8px 32px;
    @extend %cursor-pointer;
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    color: $white;
    letter-spacing: 0;
    background-color: $ink;
  }
}

.recipients-btn {
  .recipient-badge {
    .fileIcon {
      background-color: $transparent;
      stroke: $white;
      @include border-radius(0px);
      left: 15px;
      stroke-width: 3;
      height: 12px;
      width: 12px;
      vertical-align: top;
      padding: 1px;
      margin-top: 1px;
    }

    .deleteFileIcon {
      position: absolute;
      width: 12px;
      height: 12px;
      display: inline-block;
      vertical-align: top;
      background-color: $silver;
      @include border-radius(100%);
      margin-top: 1px;
      font-size: $font-size-xxs;
      color: $ink;
      top: 0px;
      @include line-height(1.3);
      left: 0;
      padding: 1px;
      stroke-width: 3px;
    }
  }
}

.recipient-list-name {
  .recipient-list-file-name {
    font-size: $font-size-xs;
  }

  .file-name-wbtn {
    background-color: $transparent;
    padding: 0 0.875rem 0 0;    

    &:hover {
      background-color: $transparent;
      cursor: default;
    }

    &:focus {
      background-color: $transparent;
    }
  }
}

.form-details {
  border-top: 2px solid $silver;
  padding: 0 20px;

  .form-label {
    font-size: $font-size-sm;
    color: $slate-grey;
    margin-bottom: 3px;
    font-family: $font-family-roboto-medium;
    font-weight: $font-weight-bold;
  }

  .form-input {
    background-color: $white;
    border: 1px solid $cadet;
    @include border-radius(4px);
    height: 30px;
    color: $ink;
    font-size: $font-size-sm;
    width: 100%;
  }
}

.form-input {
  @include input-placeholder {
    color: $cadet;
    font-size: $font-size-sm;
  }
}

.fileNameContainer {
  &.offwhitebg {
    background-color: $offwhite;
    height: 78px;
  }
}

.uploadComplete {
  background-color: $offwhite;
  padding: 20px 14px 10px;

  .uc-text,
  .showHideText {
    color: $slate-grey;
    @extend %cursor-pointer;
  }
}

.fileNameContainer {
  background-color: $pale-grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  @include border-radius(4px);
  padding: 20px;

  .fileName {
    text-transform: initial;
  }

  .filename-badge {
    height: 30px;
    @include border-radius(15px);
    padding: 8px 16px 8px 12px;
    @extend %cursor-pointer;
    max-width: 90%;
    margin: 0.6rem 0;

    span {
      @include truncate(78%);
      display: inline-block;
    }

    .fileIcon {
      background-color: $transparent;
      stroke: $white;
      @include border-radius(0px);
      left: 15px;
      stroke-width: 3;
      margin-right: 6px;
      vertical-align: top;
      width: 12px;
      height: 12px;
      padding: 1px;
      margin-top: 1px;
    }

    .deleteFileIcon {
      position: absolute;
      width: 12px;
      height: 12px;
      display: inline-block;
      vertical-align: top;
      background-color: $silver;
      @include border-radius(100%);
      margin-top: 1px;
      font-size: $font-size-xxs;
      color: $ink;
      top: 0px;
      @include line-height(1.3);
      left: 0;
      padding: 1px;
      stroke-width: 3px;
    }
  }

  label {
    font-size: $font-size-xs;
    @extend %cursor-pointer;
  }
}

.fileCancel {
  margin: 0 11px 0 3px;
  background-color: $transparent;
  @extend %border-none;
}

.fileName {
  padding-right: 22px;
}

.rr-list {
  background-color: $offwhite;
  padding: 0px 14px 10px;
}

.recipient-response-list {
  border: 1px solid $pale-grey;
  @include border-radius(4px);
  padding: 1rem;
  background-color: $offwhite;
  p {
    color: $slate-grey !important;
    font-weight: $font-weight-bold;
  }
}

.from-name-email,
.reply-to-input {
  padding: 10px 20px 13px 0;
  border-right: 1px solid $silver;
  width: 65%;

  .form-group {
    width: 45%;
    margin-right: 20px;
  }
}

.reply-to-input {
  padding-left: 36px;
  border-right: 0px;
  width: 35%;

  .form-group {
    width: 92%;
  }
}
