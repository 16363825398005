// Field Merge Button
.field-merge {
  top: 10px;
  right: 20px;

  .field-merge-btn {
    padding: 5px 10px;
    font-size: $font-size-xs;
    @include border-radius(3px);
    font-weight: $font-weight-medium;
    background-color: $offwhite;

    &:hover {
      background-color: $slate-grey;
      color: $white;
    }
  }

  .insert-smiley {
    background-color: $white;

    &:hover,
    &:focus {
      background-color: $white;
    }
  }
}

.all-recipient-label {
  font-size: $font-size-xs;
}

.testBtn {
  button {
    background-color: $slate-grey;
    color: $white;
  }
}
